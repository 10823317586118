<template>
    <PageLoader>
        <Header :title="$tc('offer', 2)" heading="h2">
            <template v-slot:actions>
                <span class="flex space-x-10">
                    <span
                        ><Button :to="{ name: 'createSolarOffer', params: { client_id: this.$route.params.client_id } }">{{ $tc('create_offer', 1) }}</Button></span
                    >
                </span>
            </template>
        </Header>

        <!-- <section class="mt-5">
            <Table :total_count="total_ean_count" :paginationOptions="paginations" @fetchData="getSearchEans">
                <table class="min-w-full divide-y divide-fibonaki-border">
                    <thead>
                        <tr>
                            <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('id', 1) }}</th>
                            <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('ean', 1) }}</th>
                            <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('address', 1) }}</th>
                            <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('country', 1) }}</th>
                        </tr>
                    </thead>
                    <tbody v-show="Object.keys(eans).length > 0">
                        <tr v-for="ean in eans" :key="ean">
                            <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500 w-1/12">{{ ean.id }}</td>
                            <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500 w-3/12">{{ ean.ean_code }}</td>
                            <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500 w-6/12">{{ ean.address.street }} {{ ean.address.number }} {{ ean.address.addition }}, {{ ean.address.postal_code.zip }} {{ ean.address.postal_code.sub_city }}</td>
                            <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500 w-2/12">{{ ean.address.country }}</td>
                        </tr>
                    </tbody>
                </table>
            </Table>
        </section> -->
    </PageLoader>
</template>

<script>
    import PageLoader from '../../components/general/PageLoader.vue';

    export default {
        components: {
            PageLoader,
        },
        data() {
            return {
                ean: {},
                validationErrors: {},
                errors: {},
            };
        },
        methods: {},
    };
</script>
